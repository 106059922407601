import { StandardizeProfile } from './types';

const standardizeProfile: StandardizeProfile = ({ profile }) =>
  profile
    ? {
        profileId: profile.profileId,
        budget: profile.budget,
        budgetConfidence: profile.budgetConfidence,
        buyerSituation: profile.buyerSituation,
        fundingSource: profile.fundingSource,
        hasLand: profile.hasLand,
        isPreApproved: profile.isPreApproved,
        landType: profile.landType,
        landZip: profile.landZip,
        lotNumber: profile.lotNumber,
        preferredBeds: profile.preferredBeds,
        preferredBaths: profile.preferredBaths,
        profileType: profile.profileType,
        propertyDescription: profile.propertyDescription,
        targetMoveDateMonth: profile.targetMoveDateMonth,
        targetMoveDateYear: profile.targetMoveDateYear,
        topFavoriteHome: profile.topFavoriteHome,
        likedFeatures:
          profile.likedFeatures?.nodes.map((feature) => feature.feature) ?? [],
      }
    : undefined;

export default standardizeProfile;
