import { StandardizeCorpCollections } from './types';

const standardizeCorpCollections: StandardizeCorpCollections = ({
  collections,
}) =>
  collections
    ? collections.nodes.map((collection) => ({
        id: collection.id,
        label: collection.label,
        models:
          collection.models?.map((model) => {
            const corpCollection = collections.nodes.find(
              ({ id }) => id === collection.id
            );

            const corpCollectionModel =
              corpCollection?.collectionModels?.nodes?.find(
                ({ modelNumber }) => modelNumber === model.modelNumber
              );

            return {
              ...model,
              ...(corpCollectionModel?.id && {
                corpCollectionModelId: corpCollectionModel.id,
              }),
            };
          }) || [],
      }))
    : null;

export default standardizeCorpCollections;
