import { useQuery } from '@apollo/client';
import { getFeatures } from './queries';
import { UseGetFeaturesService } from './types';
import standardizeFeatures from '../utils/standardizeFeatures';

const useGetFeaturesService: UseGetFeaturesService = ({
  skip,
  condition,
  token,
  ...rest
}) => {
  const { data, loading, error } = useQuery(getFeatures, {
    skip,
    ...rest,
    variables: {
      condition,
    },
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });

  const mappedFeatures = standardizeFeatures(
    data?.UserProfile?.features?.nodes
  );

  return { data: mappedFeatures, loading, error };
};

export default useGetFeaturesService;
