import useGetFeaturesService from './get';
import { UseFeaturesService } from './types';

const useFeaturesService: UseFeaturesService = (params) => {
  const featuresService = useGetFeaturesService({
    ...params,
    skip: !params.token,
  });

  return featuresService;
};

export default useFeaturesService;
