import { gql } from '@apollo/client';

export const removeFeature = gql`
  mutation removeFeatureService($deleteFeature: DeleteLikedFeatureInput!) {
    deleteLikedFeature(input: $deleteFeature) {
      feature {
        type
        url
        name
        featureId
      }
    }
  }
`;
