import { useEffect, useMemo } from 'react';
import useGetUserService from './get';
import usePatchUserService from './patch';
import { UseUserService } from './types';
import usePatchProfileService from './patchProfile';
import useAddFeatureService from './addFeature';
import useRemoveFeatureService from './removeFeature';
import useGetAuthorizedApplicationsService from './getAuthorizedApplications';
import useGetLeadSubmitted from './getLeadSubmitted';

const useUserService: UseUserService = ({
  token,
  client,
  user,
  include,
  profile,
  plannerType,
  lotNumber,
}) => {
  const getUserService = useGetUserService({
    token,
    client,
    skip: !token,
    profileType: profile?.profileType || plannerType,
    lotNumber: lotNumber,
    include,
  });

  const patchUserService = usePatchUserService({
    token,
    client,
    user,
    include,
  });

  const patchProfileService = usePatchProfileService({
    token,
    client,
  });

  const addFeatureService = useAddFeatureService({
    token,
    client,
  });

  const removeFeatureService = useRemoveFeatureService({
    token,
    client,
  });

  const getLeadSubmittedService = useGetLeadSubmitted({
    token,
    client,
    myHomeAccountId: user?.identityProviderUserId,
    dealerNumber: lotNumber,
  });

  const getAuthorizedApplicationsService = useGetAuthorizedApplicationsService({
    token,
    client,
    skip: !include?.authorizedApplications,
  });

  // update profile after planner creation
  useEffect(() => {
    if (
      token &&
      (profile?.profileType || plannerType) &&
      (profile?.profileType === 'retail' || plannerType === 'retail') &&
      (lotNumber || profile.lotNumber) &&
      getUserService.data?.profile?.profileId &&
      !patchProfileService.called
    ) {
      patchProfileService.patch({
        token,
        profile: {
          ...profile,
          profileId: getUserService.data.profile.profileId,
        },
      });
    }
  }, [
    token,
    profile,
    plannerType,
    lotNumber,
    getUserService.data,
    patchProfileService.called,
  ]);

  // refetch user data on mutations
  useEffect(() => {
    if (
      token &&
      (patchUserService.data ||
        patchProfileService.data ||
        addFeatureService.data ||
        removeFeatureService.data)
    ) {
      getUserService.refetch();
    }
  }, [
    patchUserService.data,
    patchProfileService.data,
    addFeatureService.data,
    removeFeatureService.data,
  ]);

  const mappedUser = useMemo(
    () => ({
      ...getUserService.data,
      ...(!!include?.authorizedApplications && {
        authorizedApplications: getAuthorizedApplicationsService.data,
      }),
      leadFormSubmitted: getLeadSubmittedService.data.success,
    }),
    [getUserService.data, getAuthorizedApplicationsService.data]
  );

  return {
    data: mappedUser,
    loading:
      getUserService.loading ||
      patchUserService.loading ||
      patchProfileService.loading ||
      addFeatureService.loading ||
      removeFeatureService.loading ||
      getLeadSubmittedService.loading,
    error:
      getUserService.error ||
      patchUserService.error ||
      patchProfileService.error ||
      addFeatureService.error ||
      removeFeatureService.error ||
      getLeadSubmittedService.error,
    patch: patchUserService.patch,
    patchProfile: patchProfileService.patch,
    addFeature: addFeatureService.add,
    removeFeature: removeFeatureService.remove,
  };
};

export default useUserService;
