import { useEffect } from 'react';
import useAddCollectionModel from './add';
import useCreateCorpCollection from './create';
import useGetCorpCollectionsService from './get';
import useRemoveCollectionModel from './remove';
import { UseCorpCollectionsService } from './types';

const useCorpCollections: UseCorpCollectionsService = ({
  userId,
  token,
  client,
  latitude,
  longitude,
}) => {
  const getCorpCollections = useGetCorpCollectionsService({
    userId,
    token,
    client,
    latitude,
    longitude,
  });

  const createCorpCollection = useCreateCorpCollection({
    client,
    token,
  });

  const addCollectionModel = useAddCollectionModel({
    client,
    token,
  });

  const removeCollectionModel = useRemoveCollectionModel({
    client,
    token,
  });

  // create favorites if no favorites
  useEffect(() => {
    if (
      !getCorpCollections.data?.length &&
      getCorpCollections.called &&
      !getCorpCollections.error &&
      !getCorpCollections.loading &&
      !createCorpCollection.loading &&
      !createCorpCollection.called &&
      token &&
      userId
    ) {
      createCorpCollection.create({
        collection: {
          userId,
          label: 'Favorites',
        },
        latitude,
        longitude,
      });
    }
  }, [
    getCorpCollections.data,
    getCorpCollections.called,
    getCorpCollections.loading,
    createCorpCollection,
    userId,
    token,
  ]);

  useEffect(() => {
    if (
      createCorpCollection.data ||
      addCollectionModel.data ||
      removeCollectionModel.data
    ) {
      getCorpCollections.refetch();
    }
  }, [
    createCorpCollection.data,
    addCollectionModel.data,
    removeCollectionModel.data,
  ]);

  return {
    data: getCorpCollections.data || [],
    loading:
      getCorpCollections.loading ||
      createCorpCollection.loading ||
      addCollectionModel.loading ||
      removeCollectionModel.loading,
    error:
      getCorpCollections.error ||
      createCorpCollection.error ||
      addCollectionModel.error ||
      removeCollectionModel.error,
    create: createCorpCollection.create,
    add: addCollectionModel.add,
    remove: removeCollectionModel.remove,
  };
};

export default useCorpCollections;
