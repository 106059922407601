import { useMutation } from '@apollo/client';
import { useMemo } from 'react';
import { GraphQLUser } from '../types';
import standardizeUser from '../util/standardizeUser';
import { patchUser } from './queries';
import { UsePatchUserService, Patch } from './types';
import { User } from '../types';

const allowedEmptyFields = ['optInEmail', 'optInText', 'optInCall'];

const usePatchUserService: UsePatchUserService = ({
  token,
  user,
  include,
  ...rest
}) => {
  const [patchUserMutation, patchUserService] = useMutation(patchUser, {
    ...rest,
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });

  const patch: Patch = (cbParams) => {
    const mergedParams = {
      token,
      user,
      include,
      ...cbParams,
    };

    if (mergedParams?.token && mergedParams?.user) {
      const modifiedUser = (
        Object.keys(mergedParams.user) as Array<keyof User>
      ).reduce((u, key) => {
        const modifiedKey = key === 'zipcode' ? 'zipCode' : key;
        const isEmpty = allowedEmptyFields.includes(key)
          ? false
          : !mergedParams.user[key];

        return isEmpty
          ? u
          : {
              ...u,
              [modifiedKey]: mergedParams.user[key],
            };
      }, {});

      patchUserMutation({
        variables: {
          patch: modifiedUser,
          userId: mergedParams.user.userId,
          includeCorpCollections: !!mergedParams?.include?.corpCollections,
          includeRetailCollections: !!mergedParams?.include?.retailCollections,
        },
        context: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });
    }
  };

  const mappedUser = useMemo(
    () =>
      standardizeUser({
        user: patchUserService?.data?.updateUser?.user as GraphQLUser,
        include,
      }),
    [patchUserService?.data]
  );

  return {
    ...patchUserService,
    data: mappedUser,
    patch,
  };
};

export default usePatchUserService;
