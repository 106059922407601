import { useMutation } from '@apollo/client';
import { useMemo } from 'react';
import standardizeProfile from '../util/standardizeProfile';
import { patchProfile } from './queries';
import { UsePatchProfileService, Patch } from './types';

const usePatchProfileService: UsePatchProfileService = ({
  token,
  profile,
  ...rest
}) => {
  const [patchProfileMutation, patchProfileService] = useMutation(
    patchProfile,
    {
      ...rest,
      context: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    }
  );

  const patch: Patch = (cbParams) => {
    const mergedParams = {
      token,
      profile,
      ...cbParams,
    };

    if (
      mergedParams?.token &&
      mergedParams?.profile &&
      mergedParams.profile?.profileId
    ) {
      patchProfileMutation({
        variables: {
          patch: mergedParams.profile,
          profileId: mergedParams.profile.profileId,
        },
        context: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });
    }
  };

  const mappedProfile = useMemo(
    () =>
      standardizeProfile({
        profile: patchProfileService?.data?.updateProfile?.profile,
      }),
    [patchProfileService.data]
  );

  return {
    ...patchProfileService,
    data: mappedProfile,
    patch,
  };
};

export default usePatchProfileService;
