import PropTypes from 'prop-types';
import React, { createContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import useUserService from '@services/user';
import useFeaturesService from '@services/features';
import { getSiteUrl } from '@utils/config';
import { jwtDecode } from 'jwt-decode';
import { apolloClient } from '@utils/utils';
import { ENVIRONMENT } from '@utils/config';

//Creating a default context for server side.
export const DXAccountContext = createContext({
  state: {
    account: null,
    email: null,
    features: [],
    fetchingAccount: true,
    redirectPath: null,
    postLoginRedirectPath: null,
  },
  actions: {},
});

const DXAccountContextProvider = ({ children }) => {
  const [account, setAccount] = useState();
  const [email, setEmail] = useState();
  const [error, setError] = useState(null);
  const [token, setToken] = useState('');
  const {
    isAuthenticated,
    loginWithPopup,
    logout,
    user,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
  } = useAuth0();
  const userService = useUserService({
    token,
    ...(user && {
      user: {
        identityProviderUserId: user?.sub,
        identityProviderId: 1,
        ...(user?.given_name && {
          firstName: user.given_name,
        }),
        ...(user?.first_name && {
          firstName: user.first_name,
        }),
        ...(user?.family_name && {
          lastName: user.family_name,
        }),
        ...(user?.last_name && {
          lastName: user.last_name,
        }),
      },
    }),
    plannerType: 'corp',
    profile: {
      profileType: 'corp',
    },
    include: {
      corpCollections: true,
      profile: true,
    },
    content: {
      FirstName: user?.given_name || account?.firstName || '',
      LastName: user?.family_name || account?.lastName || '',
      EmailAddress: user?.email || email,
      PortalName: 'Corporate',
      DealerNumber: '',
      MarketingMeta: {},
    },
  });

  const featureService = useFeaturesService({
    client: apolloClient,
    token: token,
    condition: {
      type: 'corp',
    },
  });

  //We start the context by searching for the account with useEffect.
  const [fetchingAccount, setFetchingAccount] = useState(true);
  const [redirectPath, setRedirectPath] = useState();
  const [postLoginRedirectPath, setPostLoginRedirectPath] = useState();

  // get auth0 token
  useEffect(() => {
    if (user && !token) {
      getAccessTokenSilently()
        .then((t) => {
          setToken(t);
        })
        .catch(setError);
    }
  }, [user, token]);

  // update token on expiration
  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);
      const date = new Date();
      const expirationDifference = decodedToken?.exp
        ? decodedToken?.exp - date.getTime() / 1000
        : -1;

      if (expirationDifference <= 0) {
        getAccessTokenSilently()
          .then((t) => {
            setToken(t);
          })
          .catch(setError);
      } else {
        setTimeout(() => {
          getAccessTokenSilently()
            .then((t) => {
              setToken(t);
            })
            .catch(setError);
        }, expirationDifference * 1000);
      }
    }
  }, [token]);

  // handle error
  useEffect(() => {
    if (error) {
      if (error === 'consent_required') {
        getAccessTokenWithPopup().catch(setError);
      } else if (error === 'login_required') {
        loginWithPopup().catch(setError);
      }
    }
  }, [error]);

  useEffect(() => {
    if (isAuthenticated && !userService.loading && userService.data) {
      userService.data !== account ? setAccount(userService.data) : null;
      setEmail(user.email);
    }
  }, [userService.data, account]); //eslint-disable-line

  useEffect(() => {
    if (
      ENVIRONMENT !== 'prod' ||
      !userService.data.userId ||
      !window?.LogRocket
    ) {
      return;
    }
    window.LogRocket.identify(userService.data.identityProviderUserId, {
      name: `${userService.data.firstName} ${userService.data.lastName}`.trim(),
      email: user?.email,
    });
  }, [
    userService.data.userId,
    userService.data.identityProviderUserId,
    userService.data.firstName,
    userService.data.lastName,
    user?.email,
  ]);

  /**
   * Attempting a redirect directly through window.
   * If href set in <a> tag, code will immediately redirect before
   * login process completes causing an incomplete account state
   */
  useEffect(() => {
    account && redirectPath
      ? (window.location.href = `${getSiteUrl()}${redirectPath}`)
      : null;
  }, [account, redirectPath]); //eslint-disable-line

  const actions = {
    accountLogin: () => {
      isAuthenticated ?? actions.setUserAccount();
      return loginWithPopup();
    },
    accountSignup: () => {
      isAuthenticated ?? actions.setUserAccount();
      return loginWithPopup({ screen_hint: 'signup' });
    },
    setUserAccount: () => {
      setAccount(userService.data);
      user.email && setEmail(user.email);
      setFetchingAccount(false);
    },
    navigateToPortal: (path) => {
      if (!account) {
        actions.accountLogin();
      }
      setRedirectPath(path);
    },
    updateAccount: (firstName, lastName, phone, pronouns, zip) => {
      userService.patch({
        user: user
          ? {
              userId: Number(account.userId),
              firstName: firstName,
              lastName: lastName,
              phone: phone,
              preferredPronouns: pronouns,
              zipcode: zip,
            }
          : null,
      });
    },
    updateProfile: (profile) => {
      userService.patchProfile({
        profile,
      });
    },
    accountLogout: () => {
      if (typeof window !== 'undefined') {
        window.analytics.track('Log Out', {
          type: 'corp',
        });
      }
      setAccount([]);
      setEmail();
      setFetchingAccount(true);
      setRedirectPath();
      logout({
        logoutParams: {
          returnTo:
            typeof window !== 'undefined' ? window.location.origin : '/',
        },
      });
    },
    setPostLoginRedirectPath,
    addFeature: userService.addFeature,
    removeFeature: userService.removeFeature,
  };

  const currentContext = {
    state: {
      account,
      email,
      features: featureService.data,
      fetchingAccount,
      redirectPath,
      postLoginRedirectPath,
    },
    actions,
  };

  return (
    <DXAccountContext.Provider value={currentContext}>
      {children}
    </DXAccountContext.Provider>
  );
};

DXAccountContextProvider.propTypes = {
  children: PropTypes.any,
  internalLink: PropTypes.bool,
};

export default React.memo(DXAccountContextProvider);
