import { gql } from '@apollo/client';

export const patchProfile = gql`
  mutation patchProfileService($patch: ProfilePatch!, $profileId: Int!) {
    updateProfile(input: { patch: $patch, profileId: $profileId }) {
      profile {
        profileId
        budgetConfidence
        buyerSituation
        cashBudget
        fundingSource
        hasLand
        isPreApproved
        landType
        landZip
        lotNumber
        preApprovalAmount
        preferredBeds
        preferredBaths
        profileType
        propertyDescription
        targetMoveDateMonth
        targetMoveDateYear
        topFavoriteHome
        likedFeatures {
          nodes {
            feature {
              name
              type
              url
              featureId
            }
          }
        }
      }
    }
  }
`;
