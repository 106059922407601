import React, { useEffect, useState, useContext } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { GoogleTagManager } from '@next/third-parties/google';
import ReactTestUtils from 'react-dom/test-utils';
import { useInView } from 'react-intersection-observer';
import { pushGTMEvent } from 'pubweb-smokey/dist/utils/analytics';
import { saveUTMSessionParams } from 'pubweb-smokey/dist/utils/utils';
import { ApolloProvider } from '@apollo/client';
import { Auth0Provider } from '@auth0/auth0-react';
import PageContextProvider, { PageContext } from '@contexts/PageContext';
import DXAccountContextProvider from '@contexts/DXAccountContext';
import DXFavoritesContextProvider from '@contexts/DXFavoritesContext';
import LocationsContextProvider from '@contexts/LocationsContext';
import Header from '@components/Shared/Header/Header';
import NewsroomHeader from '@components/Newsroom/NewsroomHeader/NewsroomHeader';
import MobileNavBarContainer from '@components/Shared/MobileNavBar/MobileNavBarContainer';
import SectionTheme from '@components/Shared/SectionTheme/SectionTheme';
import Footer from '@components/Shared/Footer/Footer';
import WebsiteBanner from '@components/Shared/WebsiteBanner/WebsiteBanner';
import { GlobalWrapper as PreFooterSection } from '@components/Shared/PreFooterSection/GlobalWrapper';
import DynamicDisclaimer from '@components/Shared/Disclaimer/DynamicDisclaimer';
import Typography from '@components/Shared/Typography/Typography';
import { ThemeProvider } from 'styled-components';
import theme from '@styles/theme';
import '@styles/globals.css';
import '@components/Shared/Typography/fonts.css';
import GlobalStyle from '@styles/base';
import StudioGlobalStyle from '@styles/Studiobase.js';
import BrochureGlobalStyle from '@styles/brochurebase.js';
import {
  GTM_ID,
  ENVIRONMENT,
  CH_ALERTS_CLOSED_KEY,
  CH_COM_WEBSITE_BANNER_CONTENTFUL_KEY,
  SR_WEBSITE_BANNER_CONTENTFUL_KEY,
  NEWSROOM_WEBSITE_BANNER_CONTENTFUL_KEY,
  getAuth0Domain,
  getAuth0ClientId,
  getAuth0Scope,
  getAuth0Audience,
  getAuth0Cache,
} from '@utils/config';
import { apolloClient } from '@utils/utils';
import Colors from 'pubweb-smokey/dist/colors';
import { AppProps } from 'next/app';

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const pageContext = useContext(PageContext);
  const [showPreFooter, setShowPreFooter] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [hideMobileNav, setHideMobileNav] = useState(false);
  const [websiteBannerKey, setWebsiteBannerKey] = useState(
    CH_COM_WEBSITE_BANNER_CONTENTFUL_KEY
  );

  const { ref, inView } = useInView({
    threshold: 0,
  });

  // Some things should not be displayed for brochure page.
  const isBrochurePage = router.pathname.toLowerCase().includes('/brochure/');

  useEffect(() => {
    // TODO: convert to TS
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    pageContext.actions.setFooterInView(inView);
  }, [inView]);

  //Hook needed to populate Twitter Icon for Footer for certain pages
  //and hide mobileNavBar on pages that shouldn't display them
  useEffect(() => {
    const arr = [
      '/newsroom',
      '/sustainability',
      'social-responsibility',
      '/stories',
      '/studio',
    ];

    setHideMobileNav(false);

    for (const val of arr) {
      if (router.pathname.indexOf(val) > -1) {
        setHideMobileNav(true);
        break;
      }
    }

    if (router.pathname.includes('/newsroom')) {
      setWebsiteBannerKey(NEWSROOM_WEBSITE_BANNER_CONTENTFUL_KEY);
    } else if (router.pathname.includes('/social-responsibility')) {
      setWebsiteBannerKey(SR_WEBSITE_BANNER_CONTENTFUL_KEY);
    }

    // Hide mobile nav on home details page
    if (router.pathname.includes('/homes/')) {
      setHideMobileNav(true);
    }
  }, [router.pathname]);

  // push custom GTM Global PageView event
  const pageview = () => {
    pushGTMEvent('globalPageView');

    if (typeof window !== 'undefined') {
      window.analytics.page();
    }
  };

  useEffect(() => {
    window.onload = () => {
      // trigger GTM Global PageView event once on initial load
      pageview();

      // TODO: convert to TS
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (ENVIRONMENT === 'prod') {
        // trigger LogRocket logging once on initial load
        window.LogRocket.init('uaifoo/claytonhomescom');
      }
    };
  }, []);

  // trigger GTM Global PageView event on route change
  useEffect(() => {
    router.events.on('routeChangeComplete', pageview);
    return () => {
      router.events.off('routeChangeComplete', pageview);
    };
  }, [router.events]);

  // save any utm params to local session cache
  const pageRouterLocation = router.asPath;
  useEffect(() => {
    saveUTMSessionParams(
      pageRouterLocation.substring(pageRouterLocation.indexOf('?'))
    );
  }, []);

  const handleEnterAsClick = (e: KeyboardEvent): void => {
    if (e.keyCode == 13) {
      const currentActiveElement =
        document.activeElement?.tagName.toLowerCase();

      switch (currentActiveElement) {
        case 'input':
        case 'select':
        case 'button':
        case 'a':
        case 'textarea':
          return;

        default:
          if (document.activeElement) {
            ReactTestUtils.Simulate.click(document.activeElement);
          }
          break;
      }
    }
  };

  useEffect(() => {
    const arr = ['/get-inspired', '/contact-us', '/faq', '/locations'];

    setShowPreFooter(false);
    setShowDisclaimer(false);

    for (const route of arr) {
      if (router.pathname === '/') {
        setShowPreFooter(true);
        setShowDisclaimer(true);
        break;
      } else if (router.pathname.includes(route)) {
        setShowPreFooter(true);
        break;
      }
    }
  }, [router.pathname]);

  useEffect(() => {
    document.addEventListener('keydown', handleEnterAsClick);
  }, []);

  return (
    <>
      <Head>
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=5"
        />
        <link
          rel="shortcut icon"
          href="/public-assets/favicon.ico"
          sizes="any"
        />
        <link
          rel="apple-touch-icon"
          href="/public-assets/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/public-assets/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/public-assets/favicon-16x16.png"
        />
        <link
          rel="mask-icon"
          href="/public-assets/safari-pinned-tab.svg"
          color="#5bbad5"
        />
        <link rel="manifest" href="/public-assets/site.webmanifest" />
        <link rel="preconnect" href="https://s.pinimg.com" />
        <link rel="preconnect" href="https://dev.visualwebsiteoptimizer.com" />
        <link rel="preconnect" href="https://use.typekit.net" />
        <link rel="preconnect" href="https://p.typekit.net" />
      </Head>

      {/* TODO: convert to TS */}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <Auth0Provider
        domain={getAuth0Domain}
        clientId={getAuth0ClientId}
        useRefreshTokens
        cacheLocation={getAuth0Cache}
        authorizationParams={{
          audience: getAuth0Audience,
          scope: getAuth0Scope,
        }}
      >
        <ApolloProvider client={apolloClient}>
          <PageContextProvider>
            <DXAccountContextProvider>
              <LocationsContextProvider>
                <DXFavoritesContextProvider>
                  <ThemeProvider theme={theme}>
                    <Typography>
                      {router.pathname.includes('/studio') ? (
                        <StudioGlobalStyle />
                      ) : router.pathname
                          .toLowerCase()
                          .includes('/brochure/') ? (
                        <BrochureGlobalStyle />
                      ) : (
                        <GlobalStyle />
                      )}
                      <SectionTheme>
                        <WebsiteBanner
                          // TODO: convert to TS
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          contentfulKey={websiteBannerKey}
                          localStorageKey={CH_ALERTS_CLOSED_KEY}
                        />
                        <div>
                          {router.pathname.includes('/newsroom') ? (
                            <NewsroomHeader />
                          ) : (
                            !isBrochurePage && <Header />
                          )}
                        </div>

                        <div className="site-container">
                          <Component {...pageProps} />
                        </div>

                        {!hideMobileNav && <MobileNavBarContainer />}

                        {showPreFooter && <PreFooterSection />}

                        {showDisclaimer && (
                          <DynamicDisclaimer
                            textColor={`${Colors.accent.grey3.standard}`}
                            disclaimerContent="\* Advertised starting sales prices are for the home only. Delivery and installation costs are not included unless otherwise stated.
                           Starting prices shown on this website are subject to change, see your local Home Center for current and specific home and pricing information.
                           Sales price does not include other costs such as taxes, title fees, insurance premiums, filing or recording fees, land or improvements to the land,
                           optional home features, optional delivery or installation services, wheels and axles, community or homeowner association fees, or any other items not
                           shown on your Sales Agreement, Retailer Closing Agreement and related documents (your SA/RCA). If you purchase a home, your SA/RCA will show the details
                           of your purchase. Homes available at the advertised sales price will vary by retailer and state. Artists’ renderings of homes are only representations
                           and actual home may vary. Floor plan dimensions are approximate and based on length and width measurements from exterior wall to exterior wall.
                           We invest in continuous product and process improvement. All home series, floor plans, specifications, dimensions, features, materials, and availability
                           shown on this website are subject to change. Images may show options not included in base price.
                          <br><br>**[ZERH Logo Use Guidelines (energy.gov)](https://www.energy.gov/sites/default/files/2022-11/ZERH%20Name%20and%20Logo%20Use%20Guidelines_0.pdf)"
                          />
                        )}
                        {!isBrochurePage && (
                          <div ref={ref}>
                            <Footer />
                          </div>
                        )}
                      </SectionTheme>
                    </Typography>
                  </ThemeProvider>
                </DXFavoritesContextProvider>
              </LocationsContextProvider>
            </DXAccountContextProvider>
          </PageContextProvider>
        </ApolloProvider>
      </Auth0Provider>
      <GoogleTagManager gtmId={GTM_ID} />
    </>
  );
}

export default MyApp;
