import { useMutation } from '@apollo/client';
import { createCorpCollection } from './queries';
import { UseCreateCorpCollection, Create } from './types';

const useCreateCorpCollection: UseCreateCorpCollection = ({
  client,
  token,
  collection,
  latitude,
  longitude,
}) => {
  const [createCorpCollectionMutation, createCorpCollectionService] =
    useMutation(createCorpCollection, {
      client,
      context: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    });

  const create: Create = (cbParams) => {
    const mergedParams = {
      token,
      collection,
      latitude,
      longitude,
      ...cbParams,
    };
    if (
      mergedParams?.collection?.label &&
      mergedParams?.collection?.userId &&
      mergedParams?.latitude &&
      mergedParams.longitude
    ) {
      createCorpCollectionMutation({
        variables: {
          collection: mergedParams.collection,
          latitude: mergedParams.latitude,
          longitude: mergedParams.longitude,
        },
        context: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });
    }
  };

  const mappedCollection =
    createCorpCollectionService?.data?.createCorpCollection || null;

  return {
    ...createCorpCollectionService,
    data: mappedCollection,
    create,
  };
};

export default useCreateCorpCollection;
