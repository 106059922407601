import { gql } from '@apollo/client';

export const getAuthorizedApplications = gql`
  query getAuthorizedApplicationsService {
    MyHomeAccount {
      user {
        authorizedApplications {
          audience
          clientId
          isRetail
          lotNumber
          name
          userId
        }
      }
    }
  }
`;
