import PropTypes from 'prop-types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import useCorpCollectionsService from '@services/corpCollections';
import { DXAccountContext } from './DXAccountContext';
import { jwtDecode } from 'jwt-decode';
import { apolloClient } from '@utils/utils';
import { LocationsContext } from './LocationsContext';
import { useAuth0 } from '@auth0/auth0-react';

export const DXFavoritesContext = createContext({
  state: {
    favoriteModels: [],
    favoritesCollection: {},
    modelsLoading: true,
  },
  actions: {},
});

const DXFavoritesContextProvider = ({ children }) => {
  const dxAccountContext = useContext(DXAccountContext);
  const [favoritesCollection, setFavoritesCollection] = useState(null);
  const [favoriteModels, setFavoriteModels] = useState(null);
  const [modelsLoading, setModelsLoading] = useState(true);
  const [modelFavoriteStaging, setModelFavoriteStaging] = useState('');
  const [error, setError] = useState(null);
  const [token, setToken] = useState('');
  const location = useContext(LocationsContext);
  const {
    user,
    getAccessTokenSilently,
    loginWithPopup,
    getAccessTokenWithPopup,
  } = useAuth0();

  const collectionsService = useCorpCollectionsService({
    userId: dxAccountContext?.state?.account?.userId,
    token: token,
    client: apolloClient,
    latitude: location.state?.geocode?.latitude,
    longitude: location.state?.geocode?.longitude,
  });

  const addFavoriteModel = (modelNumber) =>
    collectionsService.add({
      corpCollectionId: collectionsService?.data[0]?.id,
      modelNumber,
    });

  const removeFavoriteModel = (model) => {
    // ----- Also remove top favorite home if it exists and is the same model number.
    if (dxAccountContext?.state?.account?.profile?.topFavoriteHome) {
      if (
        JSON.parse(dxAccountContext?.state?.account?.profile?.topFavoriteHome)
          .modelNumber === model.modelNumber
      ) {
        // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
        const { likedFeatures, ...newProfile } = dxAccountContext?.state
          ?.account?.profile || {
          likedFeatures: [],
          profileId: 0,
          profileType: 'corp',
        };

        // Remove top favorite home.
        dxAccountContext?.actions?.updateProfile({
          ...newProfile,
          topFavoriteHome: null,
        });
      }
    }

    // Remove from favorites
    collectionsService.remove(model.corpCollectionModelId);
  };

  // get auth0 token
  useEffect(() => {
    if (user && !token) {
      getAccessTokenSilently()
        .then((t) => {
          setToken(t);
        })
        .catch(setError);
    }
  }, [user, token]);

  // update token on expiration
  useEffect(() => {
    if (token) {
      const decodedToken = jwtDecode(token);
      const date = new Date();
      const expirationDifference = decodedToken?.exp
        ? decodedToken?.exp - date.getTime() / 1000
        : -1;

      if (expirationDifference <= 0) {
        getAccessTokenSilently()
          .then((t) => {
            setToken(t);
          })
          .catch(setError);
      } else {
        setTimeout(() => {
          getAccessTokenSilently()
            .then((t) => {
              setToken(t);
            })
            .catch(setError);
        }, expirationDifference * 1000);
      }
    }
  }, [token]);

  // handle error
  useEffect(() => {
    if (error) {
      if (error === 'consent_required') {
        getAccessTokenWithPopup().catch(setError);
      } else if (error === 'login_required') {
        loginWithPopup().catch(setError);
      }
    }
  }, [error]);

  useEffect(() => {
    setFavoriteModels(collectionsService.data[0]?.models);
  }, [collectionsService]);

  /**
   * Hook is used to favorite/unfavorite an item if a user
   * is in a logged out state then logs in. Action will continue
   * whether a user is in the middle of logging in or is in logged
   * out state.
   */
  useEffect(() => {
    if (collectionsService?.data[0]?.id && modelFavoriteStaging) {
      actions.toggleFavoriteModel(modelFavoriteStaging);
      setModelFavoriteStaging('');
    }
  }, [modelFavoriteStaging, collectionsService?.data[0]?.id]); //eslint-disable-line react-hooks/exhaustive-deps

  const actions = {
    logout: () => {
      setFavoriteModels([]);
    },
    getFavoriteModels: () => {
      if (!dxAccountContext.state.account) {
        dxAccountContext.actions.accountLogin();
      }

      setModelsLoading(true);

      setFavoritesCollection(
        collectionsService.data.find(({ label }) => label === 'Favorites')
      );

      favoritesCollection?.models &&
        setFavoriteModels(favoritesCollection.models);
      setModelsLoading(false);
    },
    toggleFavoriteModel: (modelNumber) => {
      if (!dxAccountContext.state.account) {
        dxAccountContext.actions.accountLogin();
        setModelFavoriteStaging(modelNumber);
        return;
      }

      return favoriteModels?.find(
        (collectionModel) => collectionModel.modelNumber === modelNumber
      )
        ? removeFavoriteModel(
            favoriteModels?.find(
              (favoriteModel) => favoriteModel.modelNumber === modelNumber
            )
          )
        : addFavoriteModel(modelNumber);
    },
  };

  const currentContext = {
    state: {
      favoriteModels,
      favoritesCollection,
      modelsLoading,
    },
    actions,
  };

  return (
    <DXFavoritesContext.Provider value={currentContext}>
      {children}
    </DXFavoritesContext.Provider>
  );
};

DXFavoritesContextProvider.propTypes = {
  children: PropTypes.any,
};

export default React.memo(DXFavoritesContextProvider);
