import { useMutation } from '@apollo/client';
import { createCollectionModel } from './queries';
import { UseAddModel, Add } from './types';

const useAddModel: UseAddModel = ({ client, token }) => {
  const [addCollectionModelMutation, addCollectionModelService] = useMutation(
    createCollectionModel,
    {
      client,
      context: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    }
  );

  const add: Add = (collectionModel) => {
    addCollectionModelMutation({
      variables: {
        collectionModel,
      },
    });
  };

  const mappedCollectionModel =
    addCollectionModelService?.data?.createCollectionModel || null;

  return {
    ...addCollectionModelService,
    data: mappedCollectionModel,
    add,
  };
};

export default useAddModel;
