import { gql } from '@apollo/client';

export const createCollectionModel = gql`
  mutation createCollectionModel($collectionModel: CollectionModelInput!) {
    createCollectionModel(input: { collectionModel: $collectionModel }) {
      collectionModel {
        corpCollectionId
        corpCollectionModelId
        modelNumber
      }
    }
  }
`;
