import { gql } from '@apollo/client';

export const getCorpCollections = gql`
  query getCorpCollectionsService(
    $userId: Int!
    $latitude: Float!
    $longitude: Float!
  ) {
    corpCollections(condition: { userId: $userId }) {
      nodes {
        id: corpCollectionId
        label
        collectionModels {
          nodes {
            modelNumber
            id: corpCollectionModelId
          }
        }
        models(latitude: $latitude, longitude: $longitude) {
          lineDescription
          brandAcronym
          hasVirtualTour
          floorPlanReference
          tags
          images {
            mfgModelImageId
            modelsImagesId
            modelId
            isInspired
            createdBy
            creationDate
            lastModifiedBy
            lastModificationDate
            isMhAdvantageHome
            imageId
            reference
            caption
            sortOrder
            originalUploadedFilename
            imageType
            imageTypeAcronym
            roomId
            roomDescription
          }
          virtualTours {
            mfgModelVirtualTourId
            modelId
            virtualTourId
            virtualTourReference
            thumbnailReference
          }
          dealers
          modelId
          modelNumber
          modelDescription
          modelWidth
          modelLength
          beds
          baths
          maxSquareFeet
          lowPrice
          highPrice
          hasIfp
          hasDreamHomeDesign
          startingInThePrice
          isMhAdvantageHome
          isPremierFinancingEligible
        }
      }
    }
  }
`;
