import { gql } from '@apollo/client';

export const getUser = gql`
  query getUserService(
    $includeCorpCollections: Boolean!
    $includeRetailCollections: Boolean!
    $includeProfile: Boolean!
    $profileType: String = ""
    $lotNumber: String = ""
  ) {
    users {
      nodes {
        userId
        identityProviderId
        identityProviderUserId
        firstName
        lastName
        phone
        preferredContactMethod
        preferredContactTime
        preferredPronouns
        zipcode: zipCode
        optInCall
        optInEmail
        optInText
        preferredName
        suffix
        retailCollections @include(if: $includeRetailCollections) {
          nodes {
            id: retailCollectionId
            label
            inventoryItems {
              nodes {
                id: retailCollectionInventoryItemId
                collectionId: retailCollectionId
                modelNumber
                serialNumber
              }
            }
          }
        }
        corpCollections @include(if: $includeCorpCollections) {
          nodes {
            id: corpCollectionId
            label
            collectionModels {
              nodes {
                id: corpCollectionModelId
                collectionId: corpCollectionId
                modelNumber
              }
            }
          }
        }
        ...profileFields @include(if: $includeProfile)
      }
    }
  }

  fragment profileFields on User {
    profiles(condition: { profileType: $profileType, lotNumber: $lotNumber }) {
      nodes {
        budget
        budgetConfidence
        buyerSituation
        fundingSource
        hasLand
        isPreApproved
        landType
        landZip
        lotNumber
        preferredBeds
        preferredBaths
        profileId
        profileType
        propertyDescription
        targetMoveDateMonth
        targetMoveDateYear
        topFavoriteHome
        likedFeatures {
          nodes {
            feature {
              name
              type
              url
              featureId
            }
          }
        }
      }
    }
  }
`;
