import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { UseGetCorpCollectionsService } from './types';
import standardizeCorpCollections from '../util/standardizeCorpCollections';
import { getCorpCollections } from './queries';

const useGetCorpCollectionsService: UseGetCorpCollectionsService = ({
  userId,
  token,
  client,
  longitude,
  latitude,
}) => {
  const getCorpCollectionsService = useQuery(getCorpCollections, {
    skip: !token || !userId || !longitude || !latitude,
    client,
    variables: {
      userId,
      longitude,
      latitude,
    },
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });

  const mappedCorpCollections = useMemo(
    () =>
      standardizeCorpCollections({
        collections: getCorpCollectionsService.data?.corpCollections,
      }),
    [getCorpCollectionsService.data]
  );

  return {
    ...getCorpCollectionsService,
    data: mappedCorpCollections,
  };
};

export default useGetCorpCollectionsService;
