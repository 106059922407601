import { useQuery } from '@apollo/client';
import { getLeadSubmitted } from './queries';
import { UseGetLeadSubmitted } from './types';

const useGetLeadSubmitted: UseGetLeadSubmitted = ({
  token,
  myHomeAccountId,
  dealerNumber,
  ...rest
}) => {
  const getLeadSubmittedService = useQuery(getLeadSubmitted, {
    ...rest,
    skip: !myHomeAccountId || !token,
    variables: {
      myHomeAccountId,
      ...(!dealerNumber ? { dealerNumber } : {}),
    },
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });

  return {
    ...getLeadSubmittedService,
    data: {
      success: getLeadSubmittedService.data?.leadsubmitted?.success,
    },
  };
};

export default useGetLeadSubmitted;
