import { gql } from '@apollo/client';

export const addFeature = gql`
  mutation addLikedFeatureService($likedFeature: LikedFeatureInput!) {
    createLikedFeature(input: { likedFeature: $likedFeature }) {
      feature {
        type
        url
        name
        featureId
      }
    }
  }
`;
