import { useMutation } from '@apollo/client';
import { useMemo } from 'react';
import { removeFeature } from './queries';
import { UseRemoveFetaureService, RemoveFeature } from './types';

const useRemoveFeatureService: UseRemoveFetaureService = ({
  token,
  profileId,
  featureId,
  ...rest
}) => {
  const [removeFeatureMutation, removeFeatureService] = useMutation(
    removeFeature,
    {
      ...rest,
      variables: {
        deleteFeature: {
          profileId,
          featureId,
        },
      },
      context: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    }
  );

  const remove: RemoveFeature = (cbParams) => {
    const mergedParams = {
      token,
      ...cbParams,
    };

    if (
      mergedParams?.token &&
      mergedParams?.profileId &&
      mergedParams?.featureId
    ) {
      removeFeatureMutation({
        variables: {
          deleteFeature: {
            profileId: mergedParams.profileId,
            featureId: mergedParams.featureId,
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });
    }
  };

  const mappedFeature = useMemo(
    () => removeFeatureService?.data?.deleteLikedFeature?.feature,
    [removeFeatureService?.data]
  );

  return {
    ...removeFeatureService,
    data: mappedFeature,
    remove,
  };
};

export default useRemoveFeatureService;
