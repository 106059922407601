import { gql } from '@apollo/client';

export const getLeadSubmitted = gql`
  query Query($myHomeAccountId: String!, $dealerNumber: String) {
    leadsubmitted(
      myHomeAccountId: $myHomeAccountId
      dealerNumber: $dealerNumber
    )
  }
`;
