import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { getAuthorizedApplications } from './queries';
import {
  UseGetAuthorizedApplicationsService,
  AuthorizedApplication,
} from './types';

const useAuthorizedApplicationsService: UseGetAuthorizedApplicationsService = ({
  token,
  ...rest
}) => {
  const getAuthorizedApplicationsService = useQuery(getAuthorizedApplications, {
    ...rest,
    skip: rest?.skip || !token,
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });

  const mappedAuthorizedApplications: AuthorizedApplication[] = useMemo(
    () =>
      getAuthorizedApplicationsService?.data?.MyHomeAccount?.user
        ?.authorizedApplications,
    [getAuthorizedApplicationsService?.data]
  );

  return {
    ...getAuthorizedApplicationsService,
    data: mappedAuthorizedApplications,
  };
};

export default useAuthorizedApplicationsService;
