import { StandardizeUser } from './types';
import standardizeProfile from '../standardizeProfile';

const standardizeUser: StandardizeUser = ({ user, include = {} }) =>
  user
    ? {
        userId: user.userId,
        identityProviderId: user.identityProviderId,
        identityProviderUserId: user.identityProviderUserId,
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        phone: user.phone || '',
        preferredContactMethod: user.preferredContactMethod || [],
        preferredContactTime: user.preferredContactTime || [],
        preferredPronouns: user.preferredPronouns || '',
        zipcode: user.zipcode || '',
        optInCall: user.optInCall || false,
        optInEmail: user.optInEmail || false,
        optInText: user.optInText || false,
        preferredName: user.preferredName || '',
        suffix: user.suffix || '',
        ...(!!include?.retailCollections && {
          retailCollections:
            user.retailCollections?.nodes?.map((collection) => ({
              id: collection.id,
              label: collection.label,
              lotNumber: collection.lotNumber,
              inventoryItems:
                collection.inventoryItems.nodes.map((inventryItem) => ({
                  id: inventryItem.id,
                  collectionId: inventryItem.collectionId,
                  modelNumber: inventryItem.modelNumber,
                  serialNumber: inventryItem.serialNumber,
                })) || [],
            })) || [],
        }),
        ...(!!include?.corpCollections && {
          corpCollections:
            user.corpCollections?.nodes?.map((collection) => ({
              id: collection.id,
              label: collection.label,
              models:
                collection.collectionModels?.nodes?.map((collectionModel) => ({
                  id: collectionModel.id,
                  collectionId: collectionModel.collectionId,
                  modelNumber: collectionModel.modelNumber,
                })) || [],
            })) || [],
        }),
        ...(!!include?.profile && {
          profile: standardizeProfile({
            profile: user?.profiles?.nodes[0],
          }),
        }),
        leadFormSubmitted: user.leadFormSubmitted,
      }
    : null;

export default standardizeUser;
