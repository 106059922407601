import { gql } from '@apollo/client';

export const getFeatures = gql`
  query getFeaturesService($condition: FeatureCondition) {
    UserProfile {
      features(condition: $condition) {
        nodes {
          featureId
          name
          url
          type
        }
      }
    }
  }
`;
