import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { GraphQLUser, User } from '../types';
import standardizeUser from '../util/standardizeUser';
import { getUser } from './queries';
import { UseGetUserService } from './types';

const useGetUserService: UseGetUserService = ({
  token,
  include,
  profileType,
  lotNumber,
  ...rest
}) => {
  const getUserService = useQuery(getUser, {
    ...rest,
    skip: rest?.skip || !token,
    variables: {
      includeCorpCollections: !!include?.corpCollections,
      includeRetailCollections: !!include?.retailCollections,
      includeProfile:
        !!include?.profile &&
        !!profileType &&
        (profileType === 'retail' ? !!lotNumber : true),
      includePlannerProgress:
        !!include?.plannerProgress &&
        !!profileType &&
        (profileType === 'retail' ? !!lotNumber : true),
      profileType,
      lotNumber,
    },
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });

  const mappedUser: User | null = useMemo(
    () =>
      standardizeUser({
        user: getUserService?.data?.users?.nodes?.[0] as GraphQLUser,
        include,
      }),
    [getUserService?.data]
  );

  return {
    ...getUserService,
    data: mappedUser,
  };
};

export default useGetUserService;
