import { gql } from '@apollo/client';

export const removeCollectionModel = gql`
  mutation deleteCollectionModel($collectionModelId: Int!) {
    deleteCollectionModel(
      input: { corpCollectionModelId: $collectionModelId }
    ) {
      collectionModel {
        corpCollectionId
        corpCollectionModelId
        modelNumber
      }
    }
  }
`;
