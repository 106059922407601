import { useMutation } from '@apollo/client';
import { useMemo } from 'react';
import { addFeature } from './queries';
import { UseAddFetaureService, AddFeature } from './types';

const useAddFeatureService: UseAddFetaureService = ({
  token,
  profileId,
  featureId,
  ...rest
}) => {
  const [addFeatureMutation, addFeatureService] = useMutation(addFeature, {
    ...rest,
    variables: {
      likedFeature: {
        profileId,
        featureId,
      },
    },
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });

  const add: AddFeature = (cbParams) => {
    const mergedParams = {
      token,
      ...cbParams,
    };

    if (
      mergedParams?.token &&
      mergedParams?.profileId &&
      mergedParams?.featureId
    ) {
      addFeatureMutation({
        variables: {
          likedFeature: {
            profileId: mergedParams.profileId,
            featureId: mergedParams.featureId,
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });
    }
  };

  const mappedFeature = useMemo(
    () => addFeatureService?.data?.createLikedFeature?.feature,
    [addFeatureService?.data]
  );

  return {
    ...addFeatureService,
    data: mappedFeature,
    add,
  };
};

export default useAddFeatureService;
