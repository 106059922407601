import { useMutation } from '@apollo/client';
import { removeCollectionModel } from './queries';
import { UseRemoveCollectionModel, Remove } from './types';

const useRemoveCollectionModel: UseRemoveCollectionModel = ({
  client,
  token,
}) => {
  const [removeCollectionModelMutation, removeCollectionModelService] =
    useMutation(removeCollectionModel, {
      client,
      context: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    });

  const remove: Remove = (collectionModelId) => {
    if (collectionModelId) {
      removeCollectionModelMutation({
        variables: {
          collectionModelId,
        },
        context: {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      });
    }
  };

  const mappedCollectionModel =
    removeCollectionModelService?.data?.deleteCollectionModel || null;

  return {
    ...removeCollectionModelService,
    data: mappedCollectionModel,
    remove,
  };
};

export default useRemoveCollectionModel;
